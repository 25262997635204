.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 0px 20px;
}

.flip-countdown * {
  border: none !important;
}

.flip-countdown {
  margin-top: 100px !important;
}

@media (max-width: 800px) {
  .flip-countdown {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .flip-countdown-card {
    justify-content: center;
  }

  .flip-countdown-card * {
    font-size: 36px !important;
  }

  .flip-countdown-title {
    font-size: 16px !important;
  }
}

.theme-light {
  color: #282c34 !important;
  --text-color: #282c34 !important;
  --text-bottom-color: #282c34 !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
